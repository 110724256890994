.shop-pushdown-ad {

	.ad-text {
		font-size: 6px;
		line-height: 2em;
		letter-spacing: 2px;
		margin-left: 5px;
		font-family: Arial;
	}

	.horizontal-text {
		vertical-align: top;
	}

	.vertical-text {
		writing-mode: vertical-rl;
		margin-bottom: 7px;
		margin-left: 0;
		padding-left: 5px;
		transform: rotate(-180deg);
	}

	.app-container {
		margin: auto;
	}

	.inner-container {
		display: none;
		vertical-align: top;
	}

	.inner-container.show {
		overflow: hidden;
		display: block;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.smartphone-tall {
		.text-none,
		.inner-container {
			width: 320px;
			height: 100px;
		}

		.text-bottom {
			max-width: 320px;
			max-height: 120px;
		}

		.text-left {
			max-width: 360px;
			max-height: 100px;
		}
	}
	.pushdown {
		position: relative;
		.inner-container {
			width: 1008px;
			margin: 0 auto;
			position: relative;
		}

		.inner-container.static {
			height: 160px;
		}

		.ad-hidden {
			display: none;
		}

		.text-bottom {
			max-width: 1008px;
		}

		.ad-background, .ad-svg-background {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
			&> div {
				height: 100%;
			}
			.bg-container {
				width: 100%;
			}
			iframe {
				width: 100%;
				height: 100%;
			}
			svg {
				width: 100%;
			}
			&.hide {
				display: none;
			}
		}
		.ad-background.svg-mode {
			display: none;
		}
		.ad-svg-background{
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			background: linear-gradient(90deg, #F0F2F4 0%, rgba(255, 255, 255, 0.515625) 48.44%, #F0F2F4 100%);
			height: 100%;
			.inner-svg-div {
				display: flex;
				justify-content: center;
				align-items: center;
				&.svg-static {
					svg {
						width: 192px !important;
					}
				}
			}
		}
	}
}
